<template>
    <div class="p-col-12 p-card relative p-d-flex corectPading p-flex-column page-container">
        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loading">
            <img src="@/assets/img/spiner-loader.png" alt="loading" />
        </div>
        <router-view :loading="loading" @loadingChange="loadingChange" />
    </div>
</template>

<script>
import { getNotificationById } from '@/api/notifications';
import { requestToastHandler } from '@/main/mixins';

export default {
    name: 'index',
    mixins: [requestToastHandler],
    data: () => ({
        filters: {},
        home: { to: '/control/notifications', label: 'Уведомления' },
        notification: null,
        loading: true,
    }),

    methods: {
        async getCurrentNotification() {
            if (this.notificationId) {
                try {
                    const result = await getNotificationById(this.notificationId);
                    if (result.message) {
                        this.$requestError(result.message);
                        return;
                    }
                    this.notification = result;
                    this.loadingChange();
                } catch (error) {
                    this.$requestError(error.message);
                    this.loadingChange();
                    return;
                }
            }
        },
        currentNotificationTitle() {
            let noteTitle = '';
            if (this.notification) {
                noteTitle = this.notification.attributes.name || 'Нету пока нейм';
            }
            return noteTitle;
        },
        loadingChange(payload = false) {
            this.loading = payload;
        },
    },
    computed: {
        breadcrumb() {
            const regExp = /:notificationId/gi;
            const params = { ...this.$route.params };

            return this.$route.meta.breadcrumb.map((breadcrumb) => {
                return {
                    ...breadcrumb,
                    to: breadcrumb.to.replace(regExp, params.notificationId),
                    label: this.currentNotificationTitle() || breadcrumb.label || 'Новое уведомление ',
                };
            });
        },
        notificationId() {
            return this.$route.params.notificationId;
        },
    },
    async mounted() {
        await this.getCurrentNotification();
    },
    beforeRouteUpdate(to, from, next) {
        this.loading = true;
        next();
    },
};
</script>
<style lang="scss" scoped>
.corectPading {
    padding: 20px 16px !important;
}
.p-card {
    box-shadow: none !important;
}
.p-datatable-loading-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
}
.p-component-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}
.pi-spinner {
    font-size: 2rem;
}
</style>

